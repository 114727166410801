
<template>
    <div class="autocomplete3 pl-3">
        <div class="input-group">
            <input
                type="text"
                @input="onChange"
                v-model="search"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                id="hg78"
                class="form-control"
                />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" @click="search=''" id="inputGroupFileAddon04">x</button>
            </div>
        </div>
        <ul
            id="autocomplete3-results"
            v-show="isOpen"
            class="autocomplete3-results"
            >
            <li
                class="loading"
                v-if="isLoading"
                >
                Loading results...
            </li>
            <li
                v-else
                v-for="(result, i) in results"
                :key="i"
                @click="setResult(result)"
                @mouseover="over(result)"

                class="autocomplete3-result"
                :class="{ 'is-active': i === arrowCounter }"
                >
                {{ result.value }}
            </li>
        </ul>
    </div>
</template>

<script>
    import {debounce} from 'lodash';
    export default {
        name: 'SearchAutocomplete',
        props: {
            itemsp: {
                type: Array,
                required: false,
                default: () => [],
            },
            isAsync: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                isOpen: false,
                results: [],
                search: '',
                isLoading: false,
                arrowCounter: -1,
                items: [],
                timeout: null,
                timeouteredmeny: null,
                eredmeny: null,
            };
        },
        watch: {
            items: function (value, oldValue) {
                if (value.length !== oldValue.length) {
                    this.results = value;
                    this.isLoading = false;
                }
            },
            eredmeny: function() {
                console.log('eredmenywatch');
                this.$emit('updateeredmeny',this.eredmeny);
            }
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside)
        },
        destroyed() {
            document.removeEventListener('click', this.handleClickOutside)
        },
        methods: {
            setResult(result) {
                this.search = result.value;
                this.isOpen = false;
            },
            filterResults() {
                //this.results = this.items;
                console.log('filter');

                console.log(this.items);

                //this.results = thi
                //return;
                this.results = this.items.forEach((item) => {
                    console.log(item.value)
                    console.log(this.items.length);
                    console.log(this.results.length);
                    return item.value;
                    //return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
                });
                console.log('filter end');

            },
            debounce(func, timeout = 300) {
                let timer;
                return (...args) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        func.apply(this, args);
                    }, timeout);
                };
            },
            onChange() {
                this.$emit('input', this.search);
                console.log('onchange ' + this.search);
                console.log(this.items);

                //

                if (this.timeout)
                    clearTimeout(this.timeout);

                this.timeout = setTimeout(() => {
                    axios
                            .get('https://v6dev.utcakereso.hu/q/?q=' + this.search)
                            .then(response => (
                                        //    console.log('axios kesz');
                                        this.results = response.data)

                            );
                }, 500); // delay




                //

                /*
                 axios
                 .get('https://v6dev.utcakereso.hu/q/?q=' + this.search)
                 .then(response => (
                 //    console.log('axios kesz');
                 this.results = response.data)
                 
                 );
                 * 
                 */
                // .then(response => (console.log(response.data)));

                if (this.isAsync) {
                    this.isLoading = true;
                } else {
                    this.filterResults();
                    this.isOpen = true;
                }
            },
            handleClickOutside(event) {
                if (!this.$el.contains(event.target)) {
                    this.isOpen = false;
                    this.arrowCounter = -1;
                }
            },
            onArrowDown() {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                    this.$emit('updatelonlat', this.results[this.arrowCounter].lon, this.results[this.arrowCounter].lat);

                }
                console.log(this.results[this.arrowCounter].lat);
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                    this.$emit('updatelonlat', this.results[this.arrowCounter].lon, this.results[this.arrowCounter].lat);
                }

            },
            onEnter() {
                console.log('enter');
                if (!this.arrowCounter || this.arrowCounter == -1)
                    this.arrowCounter = 0;
                console.log('if után');
                
                //this.senderedmeny(this.search);

                this.search = this.results[this.arrowCounter].value;
                console.log('if 189');

                this.isOpen = false;
                this.$emit('updatelonlat', this.results[this.arrowCounter].lon, this.results[this.arrowCounter].lat);
                this.arrowCounter = -1;
                console.log('if 194');


            },
             async senderedmeny(s) {
                if (this.timeouteredmeny)
                    clearTimeout(this.timeouteredmeny);

                this.timeouteredmeny = setTimeout(() => {
                     axios
                            .get('https://v6dev.utcakereso.hu/q/?e=1&q=' + s)
                            .then(response => (
                                           // console.log('axios kesz');
                                        this.eredmeny = response.data)

                            );
                    console.log('eredmeny');
                    console.log(this.eredmeny);
                }, 500); // delay                
            },
            over(result) {
                this.$emit('updatelonlat', result.lon, result.lat);

                console.log('over');
            }
        },
    };
</script>

<style>
    .autocomplete3 {
        position: relative;
    }

    .autocomplete3-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        /*height: 120px;*/
        overflow: auto;
    }

    .autocomplete3-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

    .autocomplete3-result.is-active,
    .autocomplete3-result:hover {
        background-color: #4AAE9B;
        color: white;
    }
</style>