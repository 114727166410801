<template>
    <div class=" ">


        <l-marker v-if="markerstyle === 's1'" color="green"
                  :options="{attribution: 'auto',showCoverageOnHover: false}"
                  :lat-lng="[marker.lat || 47,marker.lon || 19]"
                  v-for="(marker, index3) in streets" ref="m2" :key="index3"
                  >
            <l-icon
                :options="{iconAnchor: [10,10]}"
                class-name="my-custom-icon5 anchor"
                >
                <div :class="`my-custom-icon i-${marker.type}-${marker.in} `">

                    {{ marker.c }}
                </div>
            </l-icon>
            <l-tooltip>{{ marker.label }}</l-tooltip>
            <l-popup :options="{oid: marker.oid,type:marker.type}">
                <h5>{{ marker.label }} 1</h5>
                <a href="#" @click="addlocation(marker.type,marker.oid)">Hozzáad a  kereséshez</a><br>
                <!-- <a href="#" @click="removelocation(marker.type,marker.oid)">Elvesz a   keresésből</a> -->
            </l-popup>
        </l-marker>





        <l-marker  v-if="markerstyle === 's2' || true"  color="green"
                   :options="{attribution: 'auto',showCoverageOnHover: false}"
                   :lat-lng="[marker.lat || 47,marker.lon || 19]"
                   v-for="(marker, index3) in streets" ref="m2" :key="index3"
                   >
            <l-icon
                :options="{iconAnchor: [10,10]}"
                class-name="my-custom-icon5 anchor"
                ref="gg"

                >
                <div :class="`my-custom-icon-s2 i2-${marker.type}-${marker.in} `">
                    &nbsp;
                    <div  v-if="marker.c > 99"  :class="`custom-badget-99 badget-text-color-${marker.in} badget-border-${marker.in}`">99+</div>
                    <div  v-else-if="marker.c > 1"  :class="`custom-badget badget-text-color-${marker.in} badget-border-${marker.in}`">{{ marker.c }}</div>
                </div>
            </l-icon>

            <l-tooltip>{{ marker.label }} ({{marker.c}} ingatlan)</l-tooltip>
            <l-popup :options="{oid: marker.oid,type:marker.type}">
                <h5>{{ marker.label }}</h5>
                {{marker.c}} további ingatlan<br>
                <a href="#" @click="addlocation(marker.type,marker.oid)">Hozzáad a  kereséshez</a><br>
                <!-- <a href="#" @click="removelocation(marker.type,marker.oid)">Elvesz a   keresésből</a> -->
            </l-popup>
        </l-marker>
    </div>
</template>
<script>
    import { LMarker, LIcon, LTooltip, LPopup } from "vue2-leaflet";
    export default {
        components: {
            LTooltip,
            LPopup,
            LIcon,
            LMarker
        },
        props: ['center'
                    , 'ads'
                    , 'zoom'
                    , 'publictransport'
                    , 'autopan'
                    , 'drag'
                    , 'marker'
                    , 'markerlatlng'
                    , 'userpoints'
                    , 'mapoptions'
                    // , 'streets'
                    , 'markerstyle'
                    , 'default_q'

                    // , 'options'
        ],
        data() {
            return {
                q: '',
                streets: [],
                streets_tmp: [],
                options: {'zoomControl': false},
                stops: [],
                // streets: [],
                distancies: [],
                staticAnchor: [116, 57],
                geojson: [{
                        "type": "LineString",
                        "coordinates": [[19, 47], [20, 47], [20.1, 47.1], [19, 47]]
                    }, {
                        "type": "LineString",
                        "coordinates": [[-105, 40], [-110, 45], [-115, 55]]
                    }],
                icon1: ({
                    //iconUrl: "static/images/baseball-marker.png",
                    iconSize: [320, 37],
                    iconAnchor: [1, 37]
                }),
                icon2: ({
                    // iconUrl: "static/images/baseball-marker.png",
                    iconSize: [32, 37],
                    //iconAnchor: [1, 17]
                }),
            }
        },
        mounted() {
            console.log('ads2 mapoptions');
            console.log(this.mapoptions);
            if(this.default_q)
            this.q=this.default_q;
            else             this.q='';

            this.getStreets();
        },
        watch: {
            // whenever question changes, this function will run
            mapoptions: {
                handler() {
                    //alert('m');
                    this.getStreets();
                },
                deep: true
            }
        },
        methods: {
            async getStreets() {
                //alert('ee');
                const stops4 = await axios.get('https://dev.utcakereso.hu/terkep.php/ingatlan4/s', {
                    params: {
                        minLon: this.mapoptions.bb[0],
                        minLat: this.mapoptions.bb[1],
                        maxLon: this.mapoptions.bb[2],
                        maxLat: this.mapoptions.bb[3],
                        zoom: this.mapoptions.zoom,
                        q: this.q
                    }
                }).then(response => {
                    console.log('streets ok');
                    console.log(response.data);
                    //console.log(response.data.length);
                    this.streets_tmp = [];
                    const r = process.env.MIX_JS_URL + '/images/mapicon/bus.png';
                    for (const marker in response.data) {
                        const m = {};
                        const iconsrc = '';
                        const iconsrc2 = '';
                        m = response.data[marker];
                        /*
                         m.o = 0;
                         m.over = false;
                         m.lon = response.data[marker].lon;
                         m.lat = response.data[marker].lat;
                         m.name = response.data[marker].label;
                         //m.routes = response.data[marker].routes;
                         //m.iconsrc = r;
                         //m.iconsrc2 = iconsrc2;
                         * 
                         */
                        this.streets_tmp.push(m);
                    }

                    this.streets = this.streets_tmp;
                }
                );
                console.log('ads2 axios');
//this.streets = [1,3];
                //this.streets = stops4;

                //this.clustering(stops4);

                //
//this.streets = stops;
                //console.log(stops);
            }
            ,
            clustering: function (streets) {
                var i = 0;
                var s = [];
                for (const street of streets) {
                    i++;
                    if ((i % 5) == 1 || 1) {
                        s.push(street);
                        //console.log(street);
                    }
                }
                this.streets = s;


                console.log('clustering');
                console.log(this.streets);
            }
            ,
            addlocation: function (type,id) {
                this.q += '+' + type+':'+id;
                //alert(p);
                this.getStreets();

            },
            removelocation: function (type,id) {
                this.q += '+' + type+':-'+id;
                //alert(p);
                this.getStreets();

            },
            /*
                addqlocation(type,id) {
      //alert(type);
      this.$emit('addlocation', type+':'+id );

    },
    removelocation(type,id) {
      this.$emit('removelocation', type+':-'+id );
      //alert(type);
    },
                 * 
             */
        }
    }
</script>
<style>

    .sodmeExtraClass {
        background-color: aqua;
        padding: 10px;
        border: 1px solid #333;
        border-radius: 0 20px 20px 20px;
        box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
        text-align: center;
        width: auto !important;
        height: auto !important;
        margin: 0 !important;
    }

    .someExtraClassGreen {
        background-color: green;
        /*padding: 10px;*/
        border: 1px solid #333;
        border-radius: 50%;
        box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
        text-align: center;
        width: auto !important;
        height: auto !important;
    }

    .anchor2 {
        padding-left: 26px;
    }

    .i-district-1 {
        background-color: #d35630;

    }

    .i-district-0 {
        background-color: #bbbbbb;

    }
    .i-suburb-1 {
        background-color: #d35730;

    }
    .i-suburb-0 {
        background-color: #999999;
        color: black;

    }

    .i-street-0 {
        background-color: #aeaeae;

    }
    .i-street-1 {
        background-color: #ff0000;

    }
    .i-city-1 {
        background-color: #aa0000;

    }
    .i-city-0 {
        background-color: #aaaaaa;

    }


    .i2-district-1 {
        background-color: #d35630;

    }

    .i2-district-0 {
        background-color: #bbbbbb;

    }
    .i2-suburb-1 {
        background-color: #d35730;

    }
    .i2-suburb-0 {
        background-color: #999999;
        color: black;

    }

    .i2-street-0 {
        background-color: #aeaeae;

    }
    .i2-street-1 {
        background-color: #d35730;
        ;

    }
    .i2-city-1 {
        background-color: #d35730;
        ;

    }
    .i2-city-0 {
        background-color: #aaaaaa;

    }

    .badget-text-color-1 {
        color: #d35730;
    }
    .badget-text-color-0 {
        color: #aaa;
    }

    .badget-border-0 {
        border: 1px solid #aaaaaa;
    }
    .badget-border-1 {
        border: 1px solid #d35730;
    }



    .my-custom-icon{
        width: 38px !important;
        height: 38px !important;
        margin-left: -12px;
        margin-top: -12px;
        border-radius: 18px;
        border: 1px solid #999;
        text-align: center;
        color: #eee;
        padding-top: 9px;
        font-size: 12px;
        font-weight: 800;
    }

    .my-custom-icon-figma-withoutnumber {
        width: 38px !important;
        height: 38px !important;
        /* background: url("static/figma_szamnelkul.png");  */
        background-size: 32px 32px;
        background-repeat: no-repeat;
        margin-left: -16px;
        margin-top: -16px;
        /* border-radius: 18px;
        border: 1px solid #999;
        */
        text-align: center;
        color: #eee;
        padding-top: 9px;
        font-size: 12px;
        font-weight: 800;
    }


    .my-custom-icon-s2{
        width: 16px !important;
        height: 16px !important;
        margin-left: -6px;
        margin-top: -6px;
        border-radius: 8px;
        border: 1px solid #999;
        text-align: center;
        color: #eee;
        padding-top: 9px;
        font-size: 12px;
        font-weight: 800;
        /* background-color: #d35730; */
    }

    .my-custom-icon:hover{
        width: 46px !important;
        height: 46px !important;
        margin-left: -18px;
        margin-top: -18px;
        border-radius: 23px;
        border: 3px solid #fff;
        text-align: center;
        color: #eee;
        background-color: #444;
        padding-top: 10px;
        font-size: 12px;
        font-weight: 800;
    }

    .custom-badget{
        width: 16px !important;
        height: 16px !important;
        margin-left: 6px;
        margin-top: -35px;
        border-radius: 10px;
        /* border: 1px solid #d35730;*/
        text-align: center;
        /* color: #d35730; */
        background-color: #fff;


        padding-top: 0px;
        font-size: 10px;
        font-weight: 800;
    }

    .custom-badget-99{
        width: 20px !important;
        height: 20px !important;
        margin-left: 6px;
        margin-top: -35px;
        border-radius: 10px;
        /* border: 1px solid #d35730;*/
        text-align: center;
        /* color: #d35730; */
        background-color: #fff;


        padding-top: 2px;
        font-size: 10px;
        font-weight: 800;
    }



</style>
