<template>
    <div />

</template>
<script>

import { LMap, LTileLayer } from 'vue2-leaflet'
import L from 'leaflet'
import maplibregl from 'maplibre-gl'
import '@maplibre/maplibre-gl-leaflet'
import 'maplibre-gl/dist/maplibre-gl.css'
import 'leaflet/dist/leaflet.css'


window.maplibregl = maplibregl 

export default {
  name: 'UkerportalLMaplibreLayer',
  props: ['mapObject'],
  data() {
     return {
      center: [47, 19],
      zoom: 14,
      tileLayerClass: (url, options) => L.maplibreGL(options),
      layerOptions: {
        accessToken: 'no-token',
        style: 'https://raw.githubusercontent.com/osm2vectortiles/mapbox-gl-styles/master/styles/bright-v9-cdn.json'
      }
    }     
  },
  mounted() {
    //return;
    console.log('LibreLayer.vue 14');
    console.log(L);
    console.log(this.mapObject);
    this.addlayer();

    this.$nextTick(() => {

      return;
      /*
      console.log('gl elott');
      var gl = L.maplibreGL({
        // get your own MapTiler token at https://cloud.maptiler.com/ or use MapBox style
        style: 'https://seti.utcakereso.hu/osm_liberty4.json'
       }).addTo(this.$parent.mapObject);
       *
       */
      });

      //console.log('librelayer naxttick 28');
      //console.log(this.$parent.mapObject);


    //gl.addTo(this.$parent.mapObject);



  },
  methods: {
    async addlayer() {
       // return;
      console.log('addlayer');
          console.log(this.mapObject);

      //console.log(this.$parent);
      //console.log(this.$parent.mapObject);
      //console.log(this.$refs.librelayer.$parent.mapObject);
      await this.sleep(1);
      var gl = L.maplibreGL({
        //get your own MapTiler token at https://cloud.maptiler.com/ or use MapBox style
        //style: 'https://v6dev.utcakereso.hu/utcakereso.json'
        stylea: 'https://v6dev.utcakereso.hu/osm_liberty4.json',
        style: 'https://v6dev.utcakereso.hu/utcakereso.json'
      });
      console.log('gl');
      console.log(gl);
      gl.addTo(this.mapObject);

      console.log('maplibre ok');


    },
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>

