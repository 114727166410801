
<template>
    <div class="logolayer pt-5 pb-2 mx-auto  ">
        <h1>Utcakereso.hu</h1>
    </div>
</template>
<script>

    /**
     * Add any custom component as a leaflet control-zoom
     */
    export default {
        components: {

        },
        props: [

        ],
        data() {
            return {

                show: true,


            }
        },
        watch: {
            // whenever question changes, this function will run
            mapoptions: {
                handler() {
                    //alert('m');
                    this.getPois();
                },
                deep: true
            }
        },
        mounted() {


        },
        beforeMount() {
            console.log('beforemounted');

        },
        methods: {
            mouseover(e) {
                console.log(e);
            }

        }



    };
</script>
<style>
    .logolayer {
        /*position: absolute;*/
        z-index: 2000;
        background-color: white;
        /*width: 190px;*/
        /*height: 200px;*/
        /*right: 10px;*/
    }


</style>


