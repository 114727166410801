import { render, staticRenderFns } from "./A2.vue?vue&type=template&id=70b445c3&"
import script from "./A2.vue?vue&type=script&lang=js&"
export * from "./A2.vue?vue&type=script&lang=js&"
import style0 from "vue-advanced-search/dist/AdvancedSearch.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports