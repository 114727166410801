
<template>
    <div class="bkklayer">
        
                        <div id="ptpanel">
                    <div class="ptactive" style="margin-top: 10px;border-radius: 5px;padding: 5px;background-color: #fefefe;font-weight: bold;font-size: 14px;">BKK megállók a közelben<br>
                    <label class="uk-icom-switch">
                    <input type="checkbox" id="pt"  v-model="checkedPt">
                    <span class="uk-icom-slider uk-icom-round" id="ptslider"></span>
                    </label> <span class="ptactive" style="font-size: 12px">Mutasd a térképen</span>
                    </div>
                </div>


        <l-marker :lat-lng="[marker.lat,marker.lon]" v-for="marker in stops" @mouseover="marker.over = true; o++;marker.o=o*100" @mouseout="marker.over = false" :z-index-offset="marker.o">
            <l-icon
                :icon-anchor="staticAnchor"
                label='s'
                class-name="someExtraClass"
                 
                >
                <!--<div class="headline">{{ marker.t}} - {{ marker.name }}</div>-->
                <img :src="marker.iconsrc">
            </l-icon>
                            <l-tooltip>{{ marker.name }}</l-tooltip>
                            <l-popup>{{ marker.name }}<br>
                                <span v-for="(route,key) in marker.routes" :class="'bkk-t bkk-t-'+route.type"> {{ route.short_name }}</span>
                            </l-popup>

        </l-marker>

    </div>
</template>
<script>
    import { LMarker, LIcon,LTooltip,LPopup } from "vue2-leaflet";

    /**
     * Add any custom component as a leaflet control-zoom
     */
    export default {
        components: {
            LTooltip,
            LPopup,
            LIcon,
            LMarker
        },
        props: [
            'mapoptions'
                    , 'checkedpois'
        ],
        data() {
            return {
                markerLatLng: [19, 47],
                staticAnchor: [19, 47],
                staticAnchor2: [23, 56],
                checkedPt: false,
                stops: [],
                o:0, // z-index offset, hogy a marker on mouse overre előtűnjenek
                stops_tmp: [],
                stopRootUrl: '',
                //mapoptions: null,
                
            }
        },
        watch: {
            // whenever question changes, this function will run
            mapoptions: {
                handler() {
                    //alert('m');
                    this.getStops();
                },
                deep: true
            }
        },
        mounted() {
            this.stopRootUrl = process.env.MIX_JS_URL;

            console.log('mounted');
            //console.log('mounted');
            console.log(this.checkedpois);
            this.checkedPois = this.checkedpois;

        },
        beforeMount() {


        },
        methods: {
            mouseover(e) {
              console.log(e);  
            },
            getStops() {
                this.$emit('poichange', this.checkedPois);
                console.log('o');
                console.log(this.o);
                this.o = 0;
                //return;
                if( this.mapoptions.zoom<15 || this.checkedPt == false) {
                    this.stops = [];
                    return;
                }
                axios.get(process.env.MIX_JS_PT_URL, {
                    params: {
                        minLon: this.mapoptions.bb[0],
                        minLat: this.mapoptions.bb[1],
                        maxLon: this.mapoptions.bb[2],
                        maxLat: this.mapoptions.bb[3],
                        zoom: this.mapoptions.zoom,
                        //poi: this.checkedPois
                    }
                })
                        .then(response => {
                            console.log('stopsget');
                            //console.log(response.data);
                            //console.log(response.data.length);
                            this.stops_tmp=[];
                                const r = process.env.MIX_JS_URL + '/images/mapicon/bus.png';
                            for (const marker in response.data) {
                                const m = {};
                                const iconsrc='';
                                const iconsrc2='';
                                m.o = 0;
                                m.over = false;
                                m.lon = response.data[marker].stop_lon;
                                m.lat = response.data[marker].stop_lat;
                                m.name = response.data[marker].stop_name;
                                m.routes = response.data[marker].routes;
                                m.iconsrc = r;
                                //m.iconsrc2 = iconsrc2;
                                this.stops_tmp.push(m);
                            }
                            
                            this.stops = this.stops_tmp;
                        }
                        );
            }
        }



    };
</script>
<style>
    .bkklayer {
        /*position: absolute;*/
        z-index: 2000;
        /*background-color: red;*/
        width: 190px;
        /*height: 200px;
        */
        right: 10px;
        border-radius: 6px;
        padding: 5px;
        margin-top: 10px;
        /*top: 10px;*/
    }
    
    .bkk-t {
        padding: 3px;
    }
    .bkk-t-3 {
        background-color:  #0595f5;;
    }
.uk-icom-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
}

/* Hide default HTML checkbox */
.uk-icom-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.uk-icom-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.uk-icom-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .uk-icom-slider {
    background-color: #2196F3;
}

input:focus + .uk-icom-slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .uk-icom-slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.uk-icom-slider.uk-icom-round {
    border-radius: 34px;
}

.uk-icom-slider.uk-icom-round:before {
    border-radius: 50%;
}
    
    
 
    
</style>

