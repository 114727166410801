<template>
    <div class="poilayer">
        <span v-for="category in categories">

            <label class="uk-icom-container">
                <input type="checkbox" name="poi[]" :id="category.id" :value="category.id" @change="getPois" v-model="checkedPois">
                <span class="uk-icom-checkmark"></span>
                 <span class="circle" v-bind:style="'margin-left: 2px;padding-bottom: 2px;padding-top:1px;background-color:'+   category.color +';border-radius:9999px;background-size: 16px 18px;'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                
                {{ category.name }}</label>

        </span>
        <br>
        Zoom: {{ mapoptions.zoom }} <br>
        west: {{ mapoptions.bb[0] }} <br>
        <l-marker :lat-lng="[marker.lat,marker.lon]" v-for="marker in pois" v-bind:key="marker.id" @mouseover="marker.over = true; o++;marker.o=o*100" @mouseout="marker.over = false" :z-index-offset="marker.o">
            <l-icon
                :icon-anchor="staticAnchor"
                label='s'
                class-name="someExtraClass"
                 v-if="marker.over == false"
                 
                >
                <!--<div class="headline">{{ marker.t}} - {{ marker.name }}</div>-->
                <img :src="marker.iconsrc">
            </l-icon>
            <l-icon
                :icon-anchor="staticAnchor2"
                label='s'
                class-name="kiemeltmarker"
                 v-if="marker.over == true"
                 style="z-index: 4000"
                >
                <!--<div class="headline">{{ marker.t}} - {{ marker.name }}</div>-->
                <img :src="marker.iconsrc2" style="z-index: 4000">
            </l-icon>
            
        </l-marker>

    </div>
</template>
<script>
    import { LMarker, LIcon } from "vue2-leaflet";

    /**
     * Add any custom component as a leaflet control-zoom
     */
    export default {
        components: {
            LIcon,
            LMarker
        },
        props: [
            'mapoptions'
                    , 'checkedpois'
        ],
        data() {
            return {
                markerLatLng: [19, 47],
                staticAnchor: [19, 47],
                staticAnchor2: [23, 56],
                checkedPois: [],
                pois: [],
                o:0, // z-index offset, hogy a marker on mouse overre előtűnjenek
                pois_tmp: [],
                poiRootUrl: '',
                //mapoptions: null,
                categories: [
                    {
                        name: 'Oktatás',
                        id: 'oktatas',
                        color: '#323ec8',
                        icon: 'oktatas'
                    },
                    {
                        name: 'Egészségügy',
                        id: 'egeszsegugy',
                        color: '#d93f2a',
                        icon: 'egeszseg'
                    },
                    {
                        name: 'Bolt',
                        id: 'bolt',
                        color: '#418a10',
                        icon: 'bolt'

                    },

                    {
                        name: 'Szórakozás',
                        id: 'szorakozas',
                        color: '#f7b332',
                        icon: 'szorakozas'
                    },
                    {

                        name: 'Bank',
                        id: 'bank',
                        color: '#4c4c4c',
                        icon: 'bank'
                    }
                ],
            }
        },
        watch: {
            // whenever question changes, this function will run
            mapoptions: {
                handler() {
                    //alert('m');
                    this.getPois();
                },
                deep: true
            }
        },
        mounted() {
            this.poiRootUrl = process.env.MIX_JS_URL;

            console.log('mounted');
            //console.log('mounted');
            console.log(this.checkedPois);
            console.log(this.checkedpois);
            this.checkedPois = this.checkedpois;

        },
        beforeMount() {
            console.log('beforemounted');
            console.log(this.checkedPois);

        },
        methods: {
            mouseover(e) {
              console.log(e);  
            },
            getPois() {
                this.$emit('poichange', this.checkedPois);
                console.log('o');
                console.log(this.o);
                this.o = 0;
                //return;
                
                axios.get(process.env.MIX_JS_URL + '/icom/poi', {
                    params: {
                        minLon: this.mapoptions.bb[0],
                        minLat: this.mapoptions.bb[1],
                        maxLon: this.mapoptions.bb[2],
                        maxLat: this.mapoptions.bb[3],
                        zoom: this.mapoptions.zoom,
                        amenity: this.checkedPois.join(':'),
                        //poi: this.checkedPois
                    }
                })
                        .then(response => {
                            console.log('poiget');
                            //console.log(response.data);
                            //console.log(response.data.length);
                            this.pois_tmp=[];
                                const r = process.env.MIX_JS_URL + '/images/mapicon/icom/40px/';
                                const r2 = process.env.MIX_JS_URL + '/images/mapicon/icom/56px/';
                            for (const marker in response.data) {
                                const m = {};
                                const iconsrc='';
                                const iconsrc2='';
                                m.o = 0;
                                m.over = false;
                                m.lon = response.data[marker].lon;
                                m.lat = response.data[marker].lat;
                                m.name = response.data[marker].name;
                                const item= response.data[marker];
                                if (item.t == 'restaurant') {
                                    //iconsrc = r+'restaurant.p.16.square.png';
                                    iconsrc = r + 'etterem.png';
                                    iconsrc2 = r2 + 'etterem.png';
                                } else if (item.t == 'szallashu') {
                                    iconsrc = r + 'hotel.png';
                                } else if (item.t == 'fast_food') {
                                    iconsrc = r + 'etterem.png';
                                    iconsrc2 = r2 + 'etterem.png';
                                } else if (item.t == 'cafe') {
                                    iconsrc = r + 'kavezo.png';
                                    iconsrc2 = r2 + 'kavezo.png';
                                } else if (item.t == 'pub') {
                                    iconsrc = r + 'bisztro.png';
                                    iconsrc2 = r2 + 'bisztro.png';
                                } else if (item.t == 'bar') {
                                    iconsrc = r + 'bisztro.png';
                                    iconsrc2 = r2 + 'bisztro.png';
                                } else if (item.t == 'supermarket' || item.t == 'convenience') {
                                    iconsrc = r + 'elelmiszer.png';
                                    iconsrc2 = r2 + 'elelmiszer.png';
                                } else if (item.t == 'pharmacy') {
                                    iconsrc = r + 'gyogyszertar.png';
                                    iconsrc2 = r2 + 'gyogyszertar.png';
                                    //s = new OpenLayers.Size(16,16);
                                } else if (item.t == 'playground') {
                                    iconsrc = r + 'playground.png';
                                    //s = new OpenLayers.Size(16,16);
                                } else if (item.t == 'doctors' || item.t == 'hospital' || item.t == 'dentist') {
                                    iconsrc = r + 'orvos.png';
                                    iconsrc2 = r2 + 'orvos.png';
                                } else if (item.t == 'school') {
                                    iconsrc = r + 'iskola.png';
                                    iconsrc2 = r2 + 'iskola.png';
                                } else if (item.t == 'bakery') {
                                    iconsrc = r + 'bakery.png';
                                } else if (item.t == 'hairdresser') {
                                    iconsrc = r + 'hairdresser.png';
                                } else if (item.t == 'car' || item.t == 'car_repair' || item.t == 'car_parts') {
                                    iconsrc = r + 'car.png';
                                } else if (item.t == 'clothes') {
                                    iconsrc = r + 'clothes.png';
                                } else if (item.t == 'kindergarten') {
                                    iconsrc = r + 'ovoda.png';
                                    iconsrc2 = r2 + 'ovoda.png';
                                } else if (item.t == 'university' || item.t == 'college') {
                                    iconsrc = r + 'egyetem.png';
                                    iconsrc2 = r2 + 'egyetem.png';
                                } else if (item.t == 'fuel') {
                                    iconsrc = r + 'fuel.png';
                                } else if (item.t == 'bank' || item.t == 'atm') {
                                    iconsrc = r + 'bank.png';
                                    iconsrc2 = r2 + 'bank.png';
                                    if (item.icon && 0) {
                                        iconsrc = r + '' + item.icon;

                                    }
                                } else {
                                    if (DEBUG)
                                        Ukermap.Amenity.log('else t: ' + item.t);
                                    if (0) {
                                        if (jQuery.inArray(item.t, Ukermap.Amenity.shop) > -1) {
                                            iconsrc = r + 'others.png';
                                        } else {
                                            iconsrc = r + 'others.png';
                                        }
                                    }
                                    iconsrc = r + 'atlatszo.png';
                                }
                                //console.log(response.data[marker].lon);
                                m.iconsrc = iconsrc;
                                m.iconsrc2 = iconsrc2;
                                this.pois_tmp.push(m);
                            }
                            
                            this.pois = this.pois_tmp;
                        }
                        );
            }
        }



    };
</script>
<style>
    .poilayer {
        /*position: absolute;*/
        z-index: 2000;
        background-color: white;
        width: 190px;
        /*height: 200px;*/
        right: 10px;
        top: 10px;
        border-radius: 6px;
        padding: 5px;        
    }
/* checkbox */
.uk-icom-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 2px;
}
.uk-icom-container {
}
/* Hide the browser's default checkbox */
.uk-icom-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.uk-icom-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: solid 2px #123c24;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.uk-icom-container:hover input ~ .uk-icom-checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.uk-icom-container input:checked ~ .uk-icom-checkmark {
    border: none;
    background-color: #D35630;
}

/* Create the checkmark/indicator (hidden when not checked) */
.uk-icom-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.uk-icom-container input:checked ~ .uk-icom-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.uk-icom-container .uk-icom-checkmark:after {
    left: 8px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
    
</style>

