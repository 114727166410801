<template>
    <div class="position-relative">
        <l-map style="" class="vh-100 min-vh-100" 
               :zoom="zoom" :center="center" @ready="mapready" ref="map" 
               :options="{zoomControl:false}"
               @fullscreenchange="fullscreenToggle" @update:bounds="handleMoveend">
            <!-- <l-tile-layer :url="url" :attribution="attribution"  v-if="tile"></l-tile-layer> -->
            <l-control-zoom position="bottomright"  ></l-control-zoom>
            <ukerportal-l-maplibre-layer ref="librelayer" :url="url" :attribution="attribution"  :mapObject="mapObject" v-if="mplibre && ready"></ukerportal-l-maplibre-layer>
            <div class=''>
                <div class="position-absolute" style="z-index: 10000;background-color: bluea">
                    <l-control-zoom position="topleft"  ></l-control-zoom>

                    <div class="text-center w-100 d-flex" style="background-color: white" @dblclick="stopEvent">
                        <div >
                            <ukerportal-logo />
                            <ukerportal-kereso3 class=""
                                @talalatupdate="talalatupdate" 
                                @updatelonlat="updatelonlat"
                                @updateeredmeny="updateeredmeny"
                                />
                            <ukerportal-talalatpanel :talalatok="talalatok"/>
                        </div>
                        <div>
                            <ukerportal-zoomcontroll :mapObject="mapObject" @zoomIn="zoomIn"/>
                        </div>
                    </div>
                </div>
            </div>
        </l-map>
        <adlebego />
    </div>
</template>

<script>
// DON'T load Leaflet components here!
// Its CSS is needed though, if not imported elsewhere in your application.
    import "leaflet/dist/leaflet.css"
    import { LMap, LGeoJson, LMarker, LControlZoom } from "vue2-leaflet";
    import LControlFullscreen from 'vue2-leaflet-fullscreen';
//import LControlFullscreen from './LControlFullscreen.vue';

    export default {
        components: {
            LMap,
            LGeoJson,
            LControlFullscreen,
            LMarker,
            LControlZoom
        },
        data() {
            return {
                tile: false,
                mplibre: true,
                mapObject: null,
                ready: false,
                bkklayer: true,
                checkedpois: [],
                talalatok: [],
                mapoptions: {
                    zoom: 0,
                    bb: []
                },
                poilayer: false,
                urlw: "https://icomterkep.utcakereso.hu/{z}/{x}/{y}.png",
                urlw: "https://v6dev.utcakereso.hu/utcakereso.json",
                url: "https://v6dev.utcakereso.hu/osm_liberty4.json",
                attribution:
                        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                zoom: 15,
                center: [47, 19],
                //markerLatLng: [051.504, -0.159],
                geojson: {
                    type: "FeatureCollection",
                    features: [
                        // ...
                    ],
                },
                geojsonOptions: {
                    // Options that don't rely on Leaflet methods.
                },
            };
        },
        methods: {
            updatelonlat(lon, lat) {
                console.log('map updatelonlat ' + lon);

                this.mapObject.setView([lat, lon]);
            },
            updateeredmeny(eredmeny) {
                console.log('updateeredmeny in portalmap ' );
                console.log(eredmeny );
                this.talalatok = eredmeny;
            },
            talalatupdate() {
                console.log('talalatupdate');
                //this.talalatok = [1, 2];
            },
            bkkchange() {

            },
            stopEvent(e) {
                console.log('stopevent');
                e.stopPropagation();
                return false;
            },
            fullscreenToggle() {

                this.poilayer = this.mapObject.isFullscreen();
                //this.mapObject.setZoom(this.mapoptions.zoom*1.000000001);
                //this.mapObject.$el.click();
                console.log(this.mapObject);
                this.mapObject.fire('click');

                //his.$refs.map.mapObject.invalidateSize();
                //this.$refs.map.mapObject.panTo(new L.LatLng(0, 20));;
                //setTimeout(function(o){o.invalidateSize(true);},500,this.$refs.map.mapObject);
                //this.$refs.map.mapObject.off();
                //this.$refs.map.mapObject.remove();
            },
            mapready(o) {
                console.log('o');
                console.log(o);
                this.mapObject = o;
                this.ready = true;
                console.log(this.mapObject.getBounds().getWest());

                //   const map = this.$refs.mymap.mapObject;
                //console.log(new L.Control);
                //o.addControl(new L.Control.Fullscreen());
                console.log('maoready vege');

                //console.log(this.$refs.map.mapObject.getBounds().getWest());
            },
            poichange(pois) {
                this.checkedpois = pois;
            },
            handleMoveend() {
                this.mapoptions.bb = [this.$refs.map.mapObject.getBounds().getWest(),
                    this.$refs.map.mapObject.getBounds().getSouth(),
                    this.$refs.map.mapObject.getBounds().getEast(),
                    this.$refs.map.mapObject.getBounds().getNorth()
                ];
                this.mapoptions.zoom = this.$refs.map.mapObject.getZoom();


                //this.$emit('changemapboundingbox', [this.bb,this.$refs.map.mapObject.getZoom()]);

            },
            zoomIn() {
                 this.$refs.map.mapObject.zoomIn();


                //this.$emit('changemapboundingbox', [this.bb,this.$refs.map.mapObject.getZoom()]);

            }
        },
        async beforeMount() {
            console.log('beforeMount');
            return;
            // HERE is where to load Leaflet components!
            const {circleMarker} = await import("leaflet/dist/leaflet-src.esm");

            // And now the Leaflet circleMarker function can be used by the options:
            this.geojsonOptions.pointToLayer = (feature, latLng) =>
                circleMarker(latLng, {radius: 8});
            this.mapIsReady = true;
        },
        mounted() {
            console.log('mount');

        }
    };
</script>
<style>
    .controllayerw {
        position: absolute;
        z-index: 2000;
        /*background-color: white;*/
        width: 200px;
        height: 200px;
        right: 10px;
        top: 10px;
    }

</style>