
<template>
    <div class=" ">
        <div class="border-gray-300 border">
            <div class="p-2 m-1 h3" v-on:click="zoomin">+</div>
            <div class="p-1 pt-0 m-0 m-0 h3 " v-on:click="zoomout" >-</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "zoomcontrol",
        components: {
        },
        props: ['mapObject'],

        data() {
            return {
                talalat: false,

            }

        },
        methods: {
            zoomin() {
                this.mapObject.zoomIn();
                //this.$emit('zoomIn');
            },
            zoomout() {
                this.mapObject.zoomOut();
                //this.$emit('zoomIn');
            },
            fetchResults() {

            },
            filterResults(data, text, field) {


            }

        }
    };
</script>

<style>

</style>

