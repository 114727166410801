/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.Vue = require('vue').default;


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.component('uker-map', require('./components/Uker/Map.vue').default);
Vue.component('l-maplibre-layer', require('./components/Uker/LibreLayer.vue').default);
Vue.component('poi-layer', require('./components/Uker/PoiLayer.vue').default);
Vue.component('bkk-layer', require('./components/Uker/BkkLayer.vue').default);
Vue.component('ads2', require('./components/Uker/Ads2.vue').default);

Vue.component('ukerportal-map', require('./components/Ukerportal/UkerportalMap.vue').default);
Vue.component('ukerportal-l-maplibre-layer', require('./components/Ukerportal/LibreLayer.vue').default);
Vue.component('ukerportal-poi-layer', require('./components/Ukerportal/PoiLayer.vue').default);
Vue.component('ukerportal-bkk-layer', require('./components/Ukerportal/BkkLayer.vue').default);
//Vue.component('ukerportal-kereso', require('./components/Ukerportal/UkerportalKereso.vue').default);
//Vue.component('ukerportal-kereso2', require('./components/Ukerportal/UkerportalKereso2.vue').default);
Vue.component('ukerportal-kereso3', require('./components/Ukerportal/UkerportalKereso3.vue').default);
//Vue.component('ukerportal-kereso4', require('./components/Ukerportal/UkerportalKereso4.vue').default);
Vue.component('ukerportal-talalatpanel', require('./components/Ukerportal/UkerportalTalalatpanel.vue').default);
Vue.component('ukerportal-logo', require('./components/Ukerportal/UkerportalLogo.vue').default);
Vue.component('ukerportal-zoomcontroll', require('./components/Ukerportal/UkerportalZoomControll.vue').default);
Vue.component('a2', require('./components/Ukerportal/A2.vue').default);
Vue.component('adlebego', require('./components/Ukerportal/Adlebego.vue').default);
//Vue.component('vue-autocomplete', require('./components/Ukerportal/VueAutocomplete.vue').default);

Vue.use(BootstrapVue)




import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)



const app = new Vue({
    el: '#app',
});

/*
 * 
 *
 *  vue3
import { createApp } from 'vue';

let app=createApp({});
app.component('example', require('./components/Example.vue').default );
app.component('uker-map', require('./components/Uker/Map.vue').default);

app.component('uker-mapd', require('./components/Uker/Map2.vue').default );

//app.component('l-maplibre-layer', require('./components/Uker/Maplibre.vue').default);
app.component('l-maplibre-layer', require('./components/Uker/LibreLayer.vue').default);

app.mount("#app");
 * 
 * 
 */