
<template>
  <div id="app">
    <SearchAutocomplete
        
        @updatelonlat="updatelonlat" 
        @updateeredmeny="updateeredmeny" 
    />
<l-circle-marker
      :lat-lng="center"
      :radius='50'
      color="red"
    />  
  </div>
</template>

<script>
//import SearchAutocomplete from './components/Ukerportal/SearchAutocomplete.vue'
import SearchAutocomplete from './SearchAutocomplete.vue'
import {LCircleMarker} from 'vue2-leaflet';

export default {
  name: 'App845',
  components: {
    SearchAutocomplete,
    LCircleMarker
  },
  data() {
    return {
        center : [47,19]
    }
  },
  methods: {
      updatelonlat(lon,lat) {
          console.log('kereso updatelonlat '+lon);
          this.center = [lat,lon];
          this.$emit('updatelonlat',lon,lat);
          //this.center = [lon,lat];
      },
      updateeredmeny(eredmeny) {
          console.log('eredmeny updateeredmeny ');
          //this.center = [lat,lon];
          this.$emit('updateeredmeny',eredmeny);
          //this.center = [lon,lat];
      },
  }
  
}
</script>