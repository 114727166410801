
<template>
    <div v-if="talalatok.length">
        s
        <ul>
            <li v-for="e in talalatok">
                {{ e.value }}
                </li>
        </ul>
    </div>
</template>

<script>

    export default {
        name: "talalatok",
        components: {
        },
        props: [
            'talalatok'
        ],
        data() {
            return {
                talalat: false,

                inputProps: {
                    id: "autosuggest__input",
                    placeholder: "Do you feel lucky, punk?",
                    class: "form-control",
                    name: "hello"
                },
                suggestions: [],
                sectionConfigs: {
                    addresses: {
                        limit: 6,
                        label: "Destination",
                        onSelected: selected => {
                            this.selected = selected.item;
                        }
                    },
                    hotels: {
                        limit: 6,
                        label: "Hotels",
                        onSelected: selected => {
                            this.selected = selected.item;
                        }
                    }
                }
            };
        },
        methods: {
            fetchResults() {
                const query = this.query;
                console.log('query: ' + query);
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    const addressesPromise = axios.get(this.addressesUrl + query);

                    Promise.all([addressesPromise]).then(values => {
                        this.suggestions = [];
                        this.selected = null;

                        const addresses = this.filterResults(values[0].data, query, "value");

                        addresses.length &&
                                this.suggestions.push({name: "addresses", data: addresses});
                    });
                }, this.debounceMilliseconds);
            },
            filterResults(data, text, field) {
                console.log(field);
                return data;
                return data
                        .filter(item => {
                            if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
                                return item[field];
                            }
                        })
                        .sort();
            },
            renderSuggestion(suggestion) {
                if (suggestion.name === "hotels") {
                    const image = suggestion.item;
                    console.log(image);
                    /* return (
                     <div>
                     <img class={{ avatar: true }} src={image.thumbnailUrl} />
                     {image.title}
                     </div>
                     );*/
                    return this.$createElement('div', {'style': {color: 'red'}}, suggestion.name);
                } else {
                    return suggestion.item.value;
                }
            },
            getSuggestionValue(suggestion) {
                let {name, item} = suggestion;
                return name == "hotels" ? item.title : item.value;
            }
        }
    };
</script>

<style>

</style>
