<template>
    <l-map style="height:50vh" :zoom="zoom" :center="center" @ready="mapready" ref="map" @fullscreenchange="fullscreenToggle" @update:bounds="handleMoveend">
        <l-control-fullscreen position="topleft"
                              :options="{ title: { 'false': 'Teljes képernyő!', 'true': 'Visszazárás' } }" v-if="ready"
                              />
        <!-- <l-tile-layer :url="url" :attribution="attribution"  v-if="tile"></l-tile-layer> -->
        <l-maplibre-layer ref="librelayer" :url="url" :attribution="attribution"  :mapObject="mapObject" v-if="mplibre && ready"></l-maplibre-layer>
        <l-geo-json :geojson="geojson" :options="geojsonOptions" />
        <div class="controllayer" @click="stopEvent($event)">
        <poi-layer v-if="poilayer" :mapoptions="mapoptions" :checkedpois="checkedpois" @poichange="poichange"></poi-layer>
        <bkk-layer v-if="bkklayer" :mapoptions="mapoptions" :checkedpois="checkedpois" @bkkchange="bkkchange"></bkk-layer>
        <ads2  :markerstyle="markerstyle" :mapoptions="mapoptions" :default_q="default_q" v-if="ready && ads2"></ads2>
        </div>
    </l-map>
</template>

<script>
// DON'T load Leaflet components here!
// Its CSS is needed though, if not imported elsewhere in your application.
    import "leaflet/dist/leaflet.css"
    import { LMap, LGeoJson,LMarker } from "vue2-leaflet";
    import LControlFullscreen from 'vue2-leaflet-fullscreen';
//import LControlFullscreen from './LControlFullscreen.vue';

    export default {
        components: {
            LMap,
            LGeoJson,
            LControlFullscreen,
            LMarker
        },
        props: [
            'ads2',
            'markerstyle',
            'default_q',
            'default_center',
            'default_zoom',
        ],        
        data() {
            return {
                tile: false,
                mplibre: true,
                mapObject: null,
                ready: false,
                bkklayer: true,
                checkedpois: [],
                mapoptions: {
                    zoom: 0,
                    bb: []
                },
                poilayer: false,
                urlw: "https://icomterkep.utcakereso.hu/{z}/{x}/{y}.png",
                url: "https://v6dev.utcakereso.hu/osm_liberty4.json",
                attribution:
                        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                zoom: 15,
                center: [47, 19],
                //markerLatLng: [051.504, -0.159],
                geojson: {
                    type: "FeatureCollection",
                    features: [
                        // ...
                    ],
                },
                geojsonOptions: {
                    // Options that don't rely on Leaflet methods.
                },
            };
        },
        mounted() {
          if(this.default_center)  this.center=this.default_center;
          if(this.default_zoom)  this.zoom=this.default_zoom;
        },
        methods: {
            bkkchange() {
                
            },
            stopEvent(e) {
                console.log('stopevent');
                e.stopPropagation();
                  return false;
            },
            fullscreenToggle() {
                
                this.poilayer = this.mapObject.isFullscreen();
                //this.mapObject.setZoom(this.mapoptions.zoom*1.000000001);
                //this.mapObject.$el.click();
                console.log(this.mapObject);
                this.mapObject.fire('click');

                //his.$refs.map.mapObject.invalidateSize();
                //this.$refs.map.mapObject.panTo(new L.LatLng(0, 20));;
                //setTimeout(function(o){o.invalidateSize(true);},500,this.$refs.map.mapObject);
                //this.$refs.map.mapObject.off();
                //this.$refs.map.mapObject.remove();
            },
            mapready(o) {
                console.log('o');
                console.log(o);
                this.mapObject = o;
                this.ready = true;
                console.log(this.mapObject.getBounds().getWest());

                //   const map = this.$refs.mymap.mapObject;
                //console.log(new L.Control);
                //o.addControl(new L.Control.Fullscreen());
                console.log('maoready vege');

                //console.log(this.$refs.map.mapObject.getBounds().getWest());
            },
            poichange(pois) {
                this.checkedpois = pois;
            },
            handleMoveend() {
                this.mapoptions.bb = [this.$refs.map.mapObject.getBounds().getWest(),
                    this.$refs.map.mapObject.getBounds().getSouth(),
                    this.$refs.map.mapObject.getBounds().getEast(),
                    this.$refs.map.mapObject.getBounds().getNorth()
                ];
                this.mapoptions.zoom = this.$refs.map.mapObject.getZoom();


                //this.$emit('changemapboundingbox', [this.bb,this.$refs.map.mapObject.getZoom()]);

            }
        },
        async beforeMount() {
            return;
            // HERE is where to load Leaflet components!
            const {circleMarker} = await import("leaflet/dist/leaflet-src.esm");

            // And now the Leaflet circleMarker function can be used by the options:
            this.geojsonOptions.pointToLayer = (feature, latLng) =>
                circleMarker(latLng, {radius: 8});
            this.mapIsReady = true;
        },
    };
</script>
<style>
        .controllayer {
        position: absolute;
        z-index: 2000;
        /*background-color: white;*/
        width: 200px;
        height: 200px;
        right: 10px;
        top: 10px;
    }
</style>