<template>
    <div id="lebegoad">
        <Adsense
            data-ad-client="ca-pub-4233283598404045"
            data-ad-slot="9641742641"
            data-full-width-responsive="yes"
            data-ad-format="auto">
        </Adsense>
    </div>

</template>
<script>
    import Adsense from 'vue-google-adsense/dist/Adsense.min.js'
    import ScriptX from 'vue-scriptx'
    export default {
        components: {
            // Adsense
        },
        data() {
            return {
                tile: false,

            };
        },
        methods: {

        },

        mounted() {
            console.log('adlebego');
            return;
            window.google_ad_client = "ca-pub-4233283598404045";
            window.google_ad_slot = "9641742641";
            if (window.innerWidth <= 560) {
                window.google_ad_width = 468;
                window.google_ad_format = 'auto';

                window.google_ad_height = 120;
            }
            if (window.innerWidth <= 992) {
                window.google_ad_width = 980;
                window.google_ad_format = 'auto';

                window.google_ad_height = 120;
            } else if (window.innerWidth <= 1800) {
                window.google_ad_width = 1200;
                window.google_ad_format = 'auto';

                window.google_ad_height = 350;
            }
            // container is where you want the ad to be inserted
            var container = document.getElementById('lebegoad');
            var w = document.write;
            document.write = function (content) {
                container.innerHTML = content;
                document.write = w;
            };

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://pagead2.googlesyndication.com/pagead/show_ads.js';
            document.body.appendChild(script);
            //alert('adleblego');
        }
    };
</script>
<style>
    #lebegoad {
        position: relative;
        z-index:2000;
        margin-left: auto;
        margin-right: auto;
        bottom: 300px;
        width: 1300px;
        height: 300px;
        margin: auto;
        border: solid 2px white ;
    }

        @media screen and (max-width: 1200px) {
        #lebegoad {
            width: 950px;
            height: 180px;
            border: solid 2px yellow ;
            bottom: 220px;


        }
    }
    
        @media screen and (min-width: 1201px) {
        #lebegoad {
            width: 950px;
            height: 140px;
            border: solid 2px black ;
            bottom: 280px;


        }
    }
    
    /* On screens that are 992px or less, set the background color to blue */
    @media screen and (max-width: 992px) {
        #lebegoad {
            width: 950px;
            height: 180px;
            border: solid 2px blue ;
                    bottom: 220px;


        }
    }
    @media screen and (max-width: 800px) {
        #lebegoad {
            width: 750px;
            height: 180px;
            border: solid 2px red ;
                    bottom: 200px;


        }
    }
    @media screen and (max-width: 750px) {
        #lebegoad {
            width: 320px;
            height: 120px;
            border: solid 2px green ;
                    bottom: 200px;


        }
    }
</style>